@keyframes gradient {
    0% {
        background: linear-gradient(-45deg, #eee, #ddd, #aaa);
        background-size: 400% 400%;
        background-position: 0% 50%;
    }

    50% {
        background: linear-gradient(-45deg, #eee, #ddd, #aaa);
        background-size: 400% 400%;
        background-position: 100% 50%;
    }

    100% {
        background: linear-gradient(-45deg, #eee, #ddd, #aaa);
        background-size: 400% 400%;
        background-position: 0% 50%;
    }
}

.skeleton-image {
    height: 0px;
    width: 100%;
    padding-bottom: 80%;
    animation: gradient 2s ease infinite;
}

.skeleton-title {
    height: 1em;
    margin: 0.2em 0;
    width: 40%;
    animation: gradient 2s ease infinite;
}

.skeleton-text {
    height: 1em;
    margin: 0.2em 0;
    width: 60%;
    animation: gradient 2s ease infinite;
}

.skeleton-block {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: #eeeeee;
    animation: gradient 2s ease infinite;
}