@use '@angular/material' as mat;
@use 'styles.theme' as theme;

.product-image-wrapper {
    position: relative;
    width: fit-content;
}

.product-image {
    width: calc(#{var(--card-width)} - 2px);
    height: calc(#{var(--card-width)} - 2px);
    object-fit: cover;
    display: block;
}

.product-extras {
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    bottom: 12px;
    left: 16px;

    // blur
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    padding: 5px;
    border-radius: 10px;

    &.product-extras-top {
        top: 16px;
        bottom: auto;
    }
}

.product-tags {
    overflow-x: auto;
    margin: 0 -16px;
    padding: 0 16px;

    &::-webkit-scrollbar {
        display: none;
    }

    .mdc-evolution-chip-set__chips {
        flex-wrap: nowrap;
    }
}

.is-mobile {

    .product-tags {
        margin: 0 -8px;
        padding: 0 8px;
    }

    .product-extras-top {
        top: 8px;
    }
}

.product-top-buttons {
    gap: 8px;
    top: 16px;
    left: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    position: absolute !important;
}

.product-extra {
    gap: 4px;
    height: 20px;
    padding: 0 8px;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    border-radius: 10px;
    width: fit-content;
    display: flex;
    align-items: center;
    background-color: var(--mdc-chip-elevated-selected-container-color);

    &.product-future-price {
        background-color: mat.get-theme-color(theme.$theme, primary, 40);
    }

    &.green {
        background-color: #27e23d;
        color: black;
    }

    &.red {
        background-color: rgb(220, 53, 69);
    }

    .mat-icon {
        width: 16px;
        height: 16px;
        font-size: 16px;
        line-height: 16px;

        &:first-child {
            margin-left: -4px;
        }

        &:last-child {
            margin-right: -4px;
        }
    }

    &.product-seller {
        overflow: visible;
        position: relative;
        padding: 0;
        overflow: hidden;
        text-decoration: none;

        .product-seller-name {
            margin-right: 4px;
        }

        img.product-seller-logo {
            width: 20px;
            height: 20px;
            display: block;
            box-sizing: border-box;
            border: 1px solid #333;
            border-radius: 10px;
            background-color: #fff;
        }

        img.product-seller-choice {
            height: 20px;
            display: block;
            box-sizing: border-box;
            border: 1px solid #333;
            border-radius: 10px;
        }
    }
}

.product-seller-attrs {
    left: 0;
    gap: 6px;
    top: calc(100% + 4px);
    padding: 12px;
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 16px;
    background: #ffffff;
    @include mat.elevation(8, #333333, 0.5);
    opacity: 0;
    transition: opacity .2s ease;
    pointer-events: none;
    border: 1px solid #eeeeee;
    z-index: 2;

    .product-seller-attr {
        align-items: center;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        font-size: 12px;
        color: #777777;

        b {
            color: #333333;
            font-size: 14px;
        }

        small {
            font-size: 12px;
            color: #777777;
        }
    }
}

.product-seller:hover .product-seller-attrs {
    opacity: 1;
}

.product-price-wrapper {
    gap: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.product-price {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
}

.product-available {
    border: 1px solid #ddd;
    padding: 0 8px;
    font-size: 10px;
    line-height: 18px;
    border-radius: 10px;
    color: #909293;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;

    &.product-available-running-out {
        color: mat.get-theme-color(theme.$theme, primary, 40);
        border-color: mat.get-theme-color(theme.$theme, primary, 40);
    }
}

.product-unit-price {
    padding: 4px 8px;
    font-size: 10px;
    line-height: 12px;
    border-radius: 10px;
    color: #ffffff;
    white-space: nowrap;
    background-color: var(--mdc-chip-elevated-selected-container-color);
    border: 1px solid var(--mdc-chip-elevated-selected-container-color);
}

.product-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #333333;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.product-quantity {
    color: #333333;
    font-size: 16px;
    padding: 0 8px;
    line-height: 24px;
    border-radius: 12px;
    margin-right: 4px;
    color: mat.get-theme-color(theme.$theme, primary, 40);
    background-color: mat.get-theme-color(theme.$theme, primary, 95);
}

.product-stats {
    gap: 4px;
    display: flex;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 16px;
    align-items: center;
    color: #777777;
    white-space: nowrap;
}

a.product-url,
button.product-url {
    background-color: mat.get-theme-color(theme.$theme, primary, 95);
}

.product-card-actions-container {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .product-card-action {
        box-shadow: none !important;
        background-color: #ffffff;
        border: 1px solid #dddddd;
    }
}

.product-status-icon {

    &.green {
        .mat-icon {
            color: #27e23d !important;
        }
    }

    .mat-icon {
        display: block;
    }
}