@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;
@use 'styles.theme' as theme;
@use 'styles.theme_dark' as dark;
// @use "reusable-classes" as reusable;
// @use 'styles.theme_light' as theme;


@include mat.core();

$dark-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(mat.$blue-palette),
      accent: mat.define-palette(mat.$red-palette),
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@include mat.core-theme($dark-theme);

body.dark {
   @include mat.all-component-themes(dark.$theme);
//    @include reusable.reusable(dark.$theme);
 }




html {
    @include mat.all-component-themes(theme.$theme);
    @include matx.color-variants-back-compat(theme.$theme);
}

@include mat.typography-hierarchy(theme.$typography);


html,
body {
    width: 100%;
    height: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
}

@import 'styles/skeleton.scss';
@import 'styles.product.scss';

.grecaptcha-badge {
    visibility: hidden;
}

.grid-view {
    display: grid;
    grid-gap: 8px;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));

    &.truncated {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
}

.list-view {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fit, minmax(600px, auto));
}

.card-label {
    padding: 4px 8px;
    font-size: 10px;
    background-color: rgb(0 0 0 / 50%);
    color: #fff;
    border-radius: 10px;
}

.flex-truncated {
    min-width: 0;
}

.badge {
    padding: 0.2em 1em;
    border-radius: 1em;
    display: inline-block;
}

.fs-xs {
    font-size: 0.6em;
}

.tags-wrapper {
    margin: -0.2em;
}

.tag {
    padding: 0.2em 1em;
    border-radius: 1em;
    display: inline-block;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(0 0 0 / 50%);
    color: rgb(0 0 0 / 50%);
    font-size: 0.6em;
    margin: 0.2em;
    line-height: 1.2em;
}



table.table {
    border-collapse: collapse;
}

table.table td,
table.table th {
    border: 1px solid #eee;
    padding: 2px 8px;
}

.alert {
    padding: 8px 12px;
    border-radius: 3px;
    font-size: 0.8em;
    line-height: 1.2em;
}

.inline-img {
    display: inline-block;
    height: 1em;
}

.mat-drawer {
    width: var(--aside-width) !important;
}

$card-gap: 16px;
$card-width: 256px;

html {
    --card-gap: #{$card-gap};
    --card-width: #{$card-width};
    --aside-width: #{$card-width};
}


html {
    // chips
    --mdc-chip-container-height: 32px;
    --mdc-chip-elevated-selected-container-color: #333;
    --mdc-chip-selected-label-text-color: #fff;
    --mdc-chip-with-icon-selected-icon-color: #fff;

    .mdc-evolution-chip__text-label {
        gap: 8px;
        display: flex;
        align-items: center;
    }
}

html {
    // mat-menu
    --mat-menu-container-shape: 20px;
    --mat-menu-container-color: #ffffff;

    .mat-mdc-menu-panel {
        margin-top: 8px;
        max-width: 480px;
        @include mat.elevation(4);
    }
}

html {
    // mat-card
    --mdc-elevated-card-container-color: #ffffff;
}

html {
    // mat-divider
    --mat-divider-color: #eeeeee;
}

html {
    // sidenav
    --mat-sidenav-container-divider-color: #e0e0e0;
    --mat-sidenav-container-shape: 0;
    --mat-sidenav-container-background-color: #ffffff;
    --mat-sidenav-content-background-color: #eeeeee;
}

html {
    // expansion-panel
    --mat-expansion-container-background-color: #ffffff;
}

html {
    // mat-dialog
    --mat-dialog-container-max-width: 960px;

    .mat-mdc-dialog-container .mdc-dialog__surface {
        @include mat.elevation(8, #333333, 0.5);
    }

    .mat-mdc-dialog-component-host {
        max-height: calc(#{var(--app-height, 100vh)} - 96px);
    }

    .mat-mdc-dialog-content {
        max-height: initial;
    }

    .is-mobile {
        div.mobile-fullscreen {
            --mdc-dialog-container-shape: 0px;
            --mat-dialog-container-small-max-width: 100%;
            min-width: 100vw !important;
        }

        .mat-mdc-dialog-component-host {
            max-height: var(--app-height, 100vh);
        }
    }
}

html {
    // mat-datepicker

    .mat-datepicker-content {
        background-color: #ffffff;
        border-radius: 16px;
        @include mat.elevation(8, #333333, 0.5);
    }
}


html {
    // mat-table
    --mat-table-row-item-outline-color: #dddddd;
}

html {
    // mat-select
    --mat-select-panel-background-color: #fff;

    div.mat-mdc-select-panel {
        border-radius: 12px;
        overflow: auto;
    }

    .mdc-list-item__primary-text {
        flex: 1;
    }

    .mat-mdc-optgroup-label .mdc-list-item__primary-text {
        color: #777;
    }
}

html {
    // mat-snackbar
    --mdc-snackbar-container-shape: 12px;
}

html {
    // mat-tooltip
    --mdc-plain-tooltip-container-shape: 12px;

    .mdc-tooltip__surface {
        max-width: 220px;
    }
}

html {
    // mat-autocomplete
    --mat-autocomplete-container-shape: 8px;
    --mat-autocomplete-background-color: #ffffff;
}

html {
    // mat-form-field
    --mdc-filled-text-field-container-shape: 8px;

    mat-form-field {
        width: 100%;
    }
}

.mat-mdc-icon-button.mat-mdc-button-base {
    &.mat-icon-button-xsm {
        padding: 0px;
        height: 16px;
        width: 16px;
        line-height: 16px;
    }

    .mat-icon {
        height: 16px;
        width: 16px;
        font-size: 16px;
    }
}

.mdc-list-item__primary-text {
    .is-active & {
        color: mat.get-theme-color(theme.$theme, primary, 90);
    }
}

@function cards_width($n) {
    @return $n * $card-width + $card-gap * ($n + 1);
}

@function screen_width($n) {
    @return ($n + 1) * $card-width + $card-gap * ($n + 1);
}

@media screen and (max-width: 959.98px) {
    body {
        --card-width: calc((100vw - 24px) / 2);
    }
}

@media screen and (min-width: screen_width(4)) {
    html {
        --aside-width: calc(100vw - 0.5px - #{cards_width(4)});
    }
}

@media screen and (min-width: screen_width(5)) {
    html {
        --aside-width: calc(100vw - 0.5px - #{cards_width(5)});
    }
}

@media screen and (min-width: screen_width(6)) {
    html {
        --aside-width: calc(100vw - 0.5px - #{cards_width(6)});
    }
}

@media screen and (min-width: screen_width(7)) {
    html {
        --aside-width: calc(100vw - 0.5px - #{cards_width(7)});
    }
}

@media screen and (min-width: screen_width(8)) {
    html {
        --aside-width: calc(100vw - 0.5px - #{cards_width(8)});
    }
}

@media screen and (min-width: screen_width(9)) {
    html {
        --aside-width: calc(100vw - 0.5px - #{cards_width(9)});
    }
}

@media screen and (min-width: screen_width(10)) {
    html {
        --aside-width: calc(100vw - 0.5px - #{cards_width(10)});
    }
}

[matsuffix] {
    margin-right: 8px;
}

.button-loading {

    .mdc-button__label {
        transition: opacity .2s ease;
    }

    .mat-mdc-progress-spinner {
        opacity: 0;
        position: absolute;
        transition: opacity .2s ease;

        circle {
            stroke: #fff;
        }
    }

    &.mat-mdc-mini-fab {
        .mat-mdc-progress-spinner {
            circle {
                stroke: mat.get-theme-color(theme.$theme, primary, 40);
            }
        }
    }

    &.button-loading__active {
        pointer-events: none;

        .mdc-button__label,
        .mat-icon {
            opacity: 0;
        }

        .mat-mdc-progress-spinner {
            opacity: 1;
        }
    }
}

.flex {
    gap: 8px;
    display: flex;
    align-items: center;

    &.column {
        gap: 0;
        flex-direction: column;
        align-items: initial;
    }

    &.between {
        justify-content: space-between;
    }
}

.spacer {
    flex: 1;
}

@keyframes btnWiggle {
    0% {
        -webkit-transform: rotate(0deg);
    }

    2% {
        -webkit-transform: rotate(-1deg);
    }

    3.5% {
        -webkit-transform: rotate(1deg);
    }

    5% {
        -webkit-transform: rotate(0deg);
    }

    7% {
        -webkit-transform: rotate(-2deg);
    }

    8.5% {
        -webkit-transform: rotate(2deg);
    }

    10% {
        -webkit-transform: rotate(0deg);
    }

    12% {
        -webkit-transform: rotate(-2deg);
    }

    13.5% {
        -webkit-transform: rotate(2deg);
    }

    15% {
        -webkit-transform: rotate(0deg);
    }

    17% {
        -webkit-transform: rotate(-2deg);
    }

    18.5% {
        -webkit-transform: rotate(2deg);
    }

    20% {
        -webkit-transform: rotate(0deg);
    }

    22% {
        -webkit-transform: rotate(-2deg);
    }

    23.5% {
        -webkit-transform: rotate(2deg);
    }

    25% {
        -webkit-transform: rotate(0deg);
    }

    27% {
        -webkit-transform: rotate(-1deg);
    }

    28.5% {
        -webkit-transform: rotate(1deg);
    }

    30% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
    }
}